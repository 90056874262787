import { Carousel } from "@components/core/Carousel";
import { CarouselTitleWrapper } from "@components/core/Carousel/Carousel.style";
import { CollectionControls } from "@components/headings/CollectionControls";
import { TracksList } from "@components/lists/TracksList";
import { HeadingH2 } from "@components/typography/Typography.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import { useState } from "react";
import { TracksTable } from "../TracksTable";
import Loader, { VariantTypeId } from "../TracksTable/Loader";

interface Props {
	title: string;
	tableData: Track[] | Release[];
	chunkSize: number;
	hideDots?: boolean;
	viewAllPath?: string;
	tableType: "tracks" | "releases";
	dataTestId?: string;
	showPlay?: boolean;
	showAddToQueue?: boolean;
	showAddToPlaylist?: boolean;
	showReleaseDate?: boolean;
	isLoading?: boolean;
}

const CarouselTableWrapper = (props: Props) => {
	const {
		title,
		tableData,
		chunkSize,
		hideDots,
		viewAllPath,
		tableType,
		dataTestId,
		showPlay,
		showAddToQueue,
		showAddToPlaylist,
		showReleaseDate,
		isLoading,
	} = props;

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const isXl = useMediaQuery({ query: device.xl });

	const chunked: Array<Track[] | Release[]> = [];
	for (let i = 0; i < tableData.length; i += chunkSize) {
		const chunk = tableData.slice(i, i + chunkSize);
		chunked.push(chunk);
	}

	const table = (row: any, key: number) => {
		if (tableType === "tracks") {
			if (isXl) {
				return (
					<TracksTable
						location={title}
						showNumbers={false}
						key={(key === currentSlideIndex).toString()}
						tracks={row}
						isLoading={!row}
						draggable={false}
						dataTestId={dataTestId}
						showReleaseDate={showReleaseDate}
					/>
				);
			}
			return (
				<TracksList
					location={title}
					tracks={row}
					key={key}
					isLoading={!row}
					showArtwork
					showNumbers={false}
					dataTestId={dataTestId}
				/>
			);
		}
		return <></>;
	};

	return (
		<Carousel
			title={(
				<CarouselTitleWrapper>
					<HeadingH2>{title}</HeadingH2>
					{!isLoading && (
						<CollectionControls
							showPlay={showPlay}
							showAddToQueue={showAddToQueue}
							showAddToPlaylist={showAddToPlaylist}
							tracks={tableData as Track[]}
						/>
					)}
				</CarouselTitleWrapper>
			)}
			showDots={chunked.length > 1 && !hideDots}
			showRightNav={chunked.length > 1}
			viewAllPath={viewAllPath}
			onPageChange={setCurrentSlideIndex}
		>
			{isLoading ? <Loader variant={VariantTypeId.darkGrey} /> : chunked.map((row, index) => table(row, index))}
		</Carousel>
	);
};

export default CarouselTableWrapper;
