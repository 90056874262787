import { API_BASES } from "@lib/constants";
import { getRequest, postRequest } from "@lib/network/request";
import { LocationParams } from "@models/facets";

interface RecommendedQueryParams {
	params: {
		ids: number[];
		page?: number | string;
		per_page?: number | string;
	};
	accessToken: string;
	location?: LocationParams;
}

export const getVerifyFieldRequest = async (field: string, value: string) => {
	const url = "/api/account/verify";
	const params: Record<string, string> = {};
	if (field === "email") {
		params["email"] = value;
	} else if (field === "username") {
		params["username"] = value;
	}

	return getRequest({ url: url, params: params, accessToken: "" });
};

export const postRegisterAccountRequest = async (
	data: Record<string, string>,
) => {
	const url = "/api/account/signup";
	return postRequest({ url: url, data: data, accessToken: "" });
};

export const postForgotPasswordRequest = async (
	data: Record<"email", string>,
) => {
	const url = "/api/account/forgot-password";
	return postRequest({ url: url, data: data, accessToken: "" });
};

export const postResetPasswordRequest = async (
	data: Record<string, string>,
) => {
	const url = "/api/account/reset-password";
	return postRequest({ url: url, data: data, accessToken: "" });
};

export const postChangePasswordRequest = async (
	data: Record<"email", string>,
	accessToken: string,
) => {
	const url = "/api/account/change-password";
	return postRequest({ url: url, data: data, accessToken: accessToken });
};

export const getRecommendedTracksRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: object;
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/catalog/tracks/similar/";
	return getRequest(
		{
			url,
			params,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getRecommendedTracksQuery = (
	recommendedParams: RecommendedQueryParams,
) => {
	const { params, accessToken, location } = recommendedParams;
	const { ids, ...restOfParams } = params;
	const sourceIds = ids.join(",");
	return {
		queryKey: ["recommended-tracks", accessToken],
		queryFn: async () => {
			const { data } = await getRecommendedTracksRequest({
				params: { source_id: sourceIds, ...restOfParams },
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
	};
};
