export enum PreferenceId {
	Profile = "profile",
	GenrePreferences = "genre-preferences",
	IdentityPassword = "identity-password",
	Billing = "billing",
	EmailPreferences = "email-preferences",
	DownloadPreferences = "download-preferences",
	StreamingPreferences = "streaming-preferences",
	CartPreferences = "cart-preferences",
}

export type PreferencesMenuProps = {
	id: PreferenceId;
	label: string;
	href: string;
};
