import styled from "styled-components";

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
`;

export const BannerImage = styled.div`
  display: relative;
  img {
    width: 100%;
    height: auto;
  }
`;
