import { HeadingH2 } from "@components/typography/Typography.style";
import Image from "next/image";
import { BannerImage, BannerWrapper, Title } from "./FullBanner.style";
interface Props {
	title: string;
	imageUri: string;
	href: string;
}
const FullBanner = ({ title, imageUri, href }: Props) => {
	return (
		<BannerWrapper>
			<Title>
				<HeadingH2>{title}</HeadingH2>
			</Title>
			<BannerImage>
				<a href={href} target="_blank" rel="noreferrer">
					<Image
						src={imageUri}
						alt={title}
						width={0}
						height={0}
					/>
				</a>
			</BannerImage>
		</BannerWrapper>
	);
};

export default FullBanner;
