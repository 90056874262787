import { PreferenceId } from "@bptypes/preferences";
import { PREFERENCES_MENU } from "@lib/constants/preferences";
import { useSessionContext } from "@lib/context/session";
import { getMySubscriptionsQuery } from "@lib/network/my";
import { hasFlacStreamingAvailable } from "@lib/utils/subscriptions";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
	PreferencesMenuList,
	PreferencesMenuNav,
} from "./PreferencesMenu.style";
import PreferencesMenuListItem from "./PreferencesMenuListItem";

const PreferencesMenu = () => {
	const router = useRouter();
	const { t } = useTranslation("translation");
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { data: mySubscription } = useQuery<MySubscription>({ ...getMySubscriptionsQuery({ accessToken }) });
	const hasFlacStreaming = hasFlacStreamingAvailable(mySubscription);

	const filteredPreferencesMenu = PREFERENCES_MENU.filter(({ id }) => {
		if (id === PreferenceId.StreamingPreferences) {
			return hasFlacStreaming;
		}
		return true;
	});

	return (
		<PreferencesMenuNav title={t("Account.Preferences")}>
			<PreferencesMenuList>
				{filteredPreferencesMenu.map(({ label, href }) => (
					<PreferencesMenuListItem
						key={label.replace(/\s/g, "")}
						label={label}
						link={href}
						isActive={router.pathname.startsWith(href)}
					/>
				))}
			</PreferencesMenuList>
		</PreferencesMenuNav>
	);
};

export default PreferencesMenu;
