import { HeadingH2 } from "@components/typography/Typography.style";
import styled, { css } from "styled-components";

export const COLLAPSABLE_ID = "twitch_stream-collapsable";

const transition = css`
  transition: 0.35s ease-in-out;
`;

export const Container = styled.div<{ isExpanded: boolean }>`
  margin-bottom: 25px;

  #${COLLAPSABLE_ID} {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 1000px;
    ${transition};

    @media ${(props) => props.theme.device.md} {
      flex-direction: row;
    }
  }

  ${({ isExpanded }) =>
		!isExpanded &&
		css`
      #${COLLAPSABLE_ID} {
        max-height: 0px;
        overflow: hidden;
      }
    `};
`;

export const TwitchPlayer = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;

  ${({ isLoading }) => isLoading && css`
    background-color: #dddddd;
  `};
`;

export const TwitchHeaderControlsWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 5px;
  ${transition};

  button#twitch_livestream-expand_button {
    min-width: fit-content;
  }

  ${({ isExpanded }) =>
		!isExpanded &&
		css`
      background-color: ${(props) => props.theme.colors.blue[600]};
      color: ${(props) => props.theme.colors.neutrals.primary[900]};

      button#twitch_livestream-expand_button {
        background: ${(props) => props.theme.colors.neutrals.primary[900]};
      }
    `};
`;

export const TwitchPlayerTitle = styled(HeadingH2)`
  display: flex;
  align-items: center;
  float: none;
  overflow: hidden;
  margin-right: 16px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
    }

    &:hover {
      cursor: pointer;
      ${(props) => css`
        filter: drop-shadow(0 0 7px ${props.theme.colors.neutrals.primary.white});
        fill: ${props.theme.colors.neutrals.primary.white};
      `};
    }
  }
`;
