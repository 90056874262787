import PreferencesMenu from "@components/layouts/PreferencesMenu/PreferencesMenu";
import Header from "@components/layouts/header/Header";
import Sidebar from "@components/layouts/sidebar/Sidebar";
import { Notifications } from "@components/notifications";
import ANCHOR_IDS from "@lib/constants/anchor-ids";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";

import Footer from "@components/layouts/footer/Footer";
import Head from "next/head";
import React, { useState } from "react";
import { Main, MainContent, MainWrapper, PreferenceLayoutContainer } from "./PreferencesLayout.style";

interface Props {
	title?: string;
	metaDescription?: string;
	children: React.ReactNode[] | React.ReactNode;
}

const PreferencesLayout: React.FC<Props> = ({
	title,
	metaDescription,
	children,
}: Props) => {
	const [showSidebar, setShowSidebar] = useState(false);
	const { getIsSessionValid } = useSessionContext();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const authenticateLink = () => {};

	const showCartSelector = () => {};

	const showUpgradeTrackSelector = () => {};

	const showAddToPlaylist = () => {};

	const pageTitle = title ? `${title} :: Beatport` : "";
	const pageDescription = metaDescription ?
		metaDescription :
		"Download and listen to new, exclusive, electronic dance music and house tracks. Available on mp3 and wav at the world’s largest store for DJs.";

	return (
		<LayoutContext.Provider
			value={{
				showSidebar,
				setShowSidebar,
				authenticateLink,
				showCartSelector,
				showUpgradeTrackSelector,
				showAddToPlaylist,
			}}
		>
			{isLoggedInSessionValid && (
				<PreferenceLayoutContainer>
					<Head>
						<title>{pageTitle}</title>
						<meta name="description" content={pageDescription} />
						<link rel="icon" type="image/x-icon" href="/images/favicon-48x48.png" />
						<link
							href="https://js.recurly.com/v4/recurly.css"
							rel="stylesheet"
							type="text/css"
						/>
					</Head>
					<div id={ANCHOR_IDS.HEADER}>
						<Header />
					</div>
					<MainWrapper>
						<Main>
							<Notifications />
							<Sidebar showDesktop={false} />
							<PreferencesMenu />
							<MainContent>{children}</MainContent>
						</Main>
					</MainWrapper>
					<div>
						<Footer />
					</div>
				</PreferenceLayoutContainer>
			)}
		</LayoutContext.Provider>
	);
};

export default PreferencesLayout;
