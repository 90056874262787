import styled from "styled-components";
import { HEADER_HEIGHT } from "@lib/css";

export const PreferenceLayoutContainer = styled.div`
 display: flex;
 flex-direction: column;
 height: 100vh;
`;

export const MainWrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.neutrals.primary[800]};
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${HEADER_HEIGHT};
  flex-grow: 1;
`;

export const Main = styled.main`
  width: 100%;
  max-width: 1420px;
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @media ${(props) => props.theme.device.md} {
    flex-direction: row;
    padding: 0 24px;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0;
  @media ${(props) => props.theme.device.md} {
    width: calc(100% - 208px);
    padding: 24px 16px;
  }
`;
