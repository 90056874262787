import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";

export const getLiveStreams = async ({
	accessToken,
	withErrorHandling,
}: {
	accessToken: string;
	withErrorHandling?: boolean;
}) => {
	const url = "/catalog/live-streams/";
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getLiveStreamsQuery = ({
	accessToken,
}: {
	accessToken: string;
}) => ({
	queryKey: ["live-streams"],
	queryFn: async () => {
		const { data } = await getLiveStreams({
			accessToken,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getLiveStreamStages = async ({
	id,
	accessToken,
	withErrorHandling,
}: {
	id: number;
	accessToken: string;
	withErrorHandling?: boolean;
}) => {
	const url = `/catalog/live-streams/${id}/stages/`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getLiveStreamStagesQuery = ({
	id,
	accessToken,
}: {
	id: number;
	accessToken: string;
}) => ({
	queryKey: [`live-stream-stages-${id}`],
	queryFn: async () => {
		const { data } = await getLiveStreamStages({
			id,
			accessToken,
			withErrorHandling: false,
		});
		return data;
	},
});
