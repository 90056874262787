import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";
import { GetTracksParams, LocationParams } from "@models/facets";

export const MY_BEATPORT_URLS = {
	FOLLOWING: "my/beatport/",
	LABELS: "my/beatport/labels/",
	ARTISTS: "my/beatport/artists/",
	TRACKS: "my/beatport/tracks/",
};

const urlPayload = (params: any) => new URLSearchParams(params);

const filterQueryParams = (searchParams: GetTracksParams = {}): GetTracksParams => {
	const params = searchParams;
	return Object.fromEntries(
		Object.entries(params).filter(([, v]) => v !== undefined && v !== ""),
	) as GetTracksParams;
};

const buildUrl = (baseUrl: string, params?: ReturnType<typeof urlPayload>) =>
	params ? `${baseUrl}?${params}` : baseUrl;

export const getMyBeatportTracksRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const filteredQueryParams = filterQueryParams(params || {});

	if (filteredQueryParams.preorder) filteredQueryParams.preorder = null;

	if (filteredQueryParams.genres)
		filteredQueryParams.genre_id = filteredQueryParams.genres;

	if (filteredQueryParams.artists)
		filteredQueryParams.facet_artist_id = filteredQueryParams.artists;

	if (filteredQueryParams.label)
		filteredQueryParams.facet_label_id = filteredQueryParams.label;

	const url = buildUrl(
		MY_BEATPORT_URLS.TRACKS,
		urlPayload(filteredQueryParams),
	);

	return getRequest(
		{
			url,
			apiBase: API_BASES.api4,
			accessToken,
			location,
		},
		withErrorHandling,
	);
};

export const getMyBeatportTracksQuery = ({
	params,
	accessToken,
	location,
}: {
	params: GetTracksParams;
	accessToken: string;
	location?: LocationParams;
}) => ({
	queryKey: ["my-beatport-tracks", accessToken, filterQueryParams(params)],
	queryFn: async () => {
		const { data } = await getMyBeatportTracksRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getMyBeatportFollowingRequest = async (
	{ accessToken, params, withErrorHandling }: { accessToken: string; params?: GetTracksParams; withErrorHandling?: boolean },
) => {
	const url = buildUrl(
		MY_BEATPORT_URLS.FOLLOWING,
		urlPayload(filterQueryParams(params)),
	);

	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getMyBeatportFollowingQuery = (
	accessToken: string,
	params?: GetTracksParams,
) => ({
	queryKey: ["my-beatport-following", filterQueryParams(params || {})],
	queryFn: async () => {
		const { data } = await getMyBeatportFollowingRequest(
			{ accessToken: accessToken || "", params, withErrorHandling: false },
		);
		return data;
	},
});

export const getMyBeatportArtistsRequest = async (
	accessToken: string,
	withErrorHandling?: boolean,
) => getRequest(
	{
		url: MY_BEATPORT_URLS.ARTISTS,
		apiBase: API_BASES.api4,
		accessToken,
	},
	withErrorHandling,
);

export const getMyBeatportArtistsQuery = (accessToken: string) => ({
	queryKey: ["my-beatport-artists"],
	queryFn: async () => {
		const { data } = await getMyBeatportArtistsRequest(
			accessToken || "",
			false,
		);
		return data;
	},
});

export const getMyBeatportLabelsRequest = async (
	accessToken: string,
	withErrorHandling?: boolean,
) => getRequest(
	{
		url: MY_BEATPORT_URLS.LABELS,
		apiBase: API_BASES.api4,
		accessToken,
	},
	withErrorHandling,
);

export const getMyBeatportLabelsQuery = (accessToken: string) => ({
	queryKey: ["my-beatport-labels"],
	queryFn: async () => {
		const { data } = await getMyBeatportLabelsRequest(accessToken || "", false);
		return data;
	},
});
