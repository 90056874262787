import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
	Share,
} from "@components/interaction";
import { Chart } from "@models/Chart";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { Controls, Wrapper } from "./CollectionControls.style";

interface Props {
	tracks?: Track[];
	release?: Release;
	chart?: Chart;
	showPlay?: boolean;
	showAddToQueue?: boolean;
	showAddToPlaylist?: boolean;
	showAddToCart?: boolean;
	showSocial?: boolean;
	location?: string;
}

const CollectionControls: React.FC<Props> = ({
	showPlay,
	showAddToQueue,
	showAddToPlaylist,
	showAddToCart,
	showSocial,
	tracks = [],
	release,
	chart,
	location,
}) => {
	const showControls = showPlay || showAddToQueue || showAddToPlaylist;
	return (
		<Wrapper role="group" title="Collection controls">
			{showControls && (
				<Controls>
					{showPlay && (
						<Play
							tracks={tracks}
							chartId={chart?.id}
							releaseId={release?.id}
							variant="rounded"
							autoMarkAsPlayed={false}
						/>
					)}
					{showAddToQueue && (
						<AddToQueue
							tracks={tracks}
							chartId={chart?.id}
							releaseId={release?.id}
						/>
					)}
					{showAddToPlaylist && (
						<AddToPlaylist
							tracks={tracks}
							chartId={chart?.id}
							releaseId={release?.id}
						/>
					)}
				</Controls>
			)}
			{showAddToCart && (
				<AddToCart
					location={location}
					track={tracks ? tracks[0] : undefined}
					chart={chart}
					release={release}
					showPreOrderText
				/>
			)}
			{showSocial && (
				<Share track={tracks[0]} release={release} chart={chart} />
			)}
		</Wrapper>
	);
};

export default CollectionControls;
