import Button from "@components/Button";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { TwitchLiveStreamActionsContext, TwitchLiveStreamStateContext, twitchLiveStreamInitialState } from "@lib/context/twitchLiveStream";
import useScript from "@lib/hooks/useScript";
import Image from "next/image";
import { useContext, useEffect, useMemo, useState } from "react";
import {
	COLLAPSABLE_ID,
	Container,
	TwitchHeaderControlsWrapper,
	TwitchPlayer,
	TwitchPlayerTitle,
} from "./TwitchLiveStream.style";

const PLAYER_ID = "BPPlayerDivID";
const TWITCH_EMBED_URL = "https://player.twitch.tv/js/embed/v1.js";
const DEFAULT_OPTIONS = {
	width: "100%",
	height: "100%",
	autoplay: twitchLiveStreamInitialState.isExpanded,
	muted: false,
};

// Extend the window object with Twitch
declare global {
	interface Window {
		Twitch: any;
	}
}

interface optionsProps {
	width: string;
	height: string;
	autoplay: boolean;
	muted: boolean;
	channel?: string;
	video?: string;
	collection?: string;
	[key: string]: string | boolean | undefined | null;
}

interface playerProps {
	play: () => void;
	pause: () => void;
	getChannel: () => string;
	setChannel: (channel: string) => void;
	getVideo: () => string;
	setVideo: (video: string) => void;
	getCollection: () => string;
	setCollection: (collection: string) => void;
	setVolume: () => void;
}

const contentTypeDeducer = (host_vendor_url: string) => {
	const URLParams = new URLSearchParams("?" + host_vendor_url.split("?")[1]);

	if (URLParams.has("collection")) {
		return {
			contentType: "collection",
			value: URLParams.get("collection"),
		};
	} else if (URLParams.has("video")) {
		return {
			contentType: "video",
			value: URLParams.get("video"),
		};
	}

	return {
		contentType: "channel",
		value: URLParams.get("channel"),
	};
};

const TwitchLiveStream = () => {
	const [player, setPlayer] = useState<playerProps | null>(null);
	const { loading } = useScript(TWITCH_EMBED_URL);

	const {
		state: { currentStage, isExpanded },
	} = useContext(TwitchLiveStreamStateContext);
	const { host_vendor_url } = currentStage;

	const options: optionsProps = useMemo(
		() => ({
			...DEFAULT_OPTIONS,
		}),
		[],
	);

	if (host_vendor_url) {
		const { contentType, value } = contentTypeDeducer(host_vendor_url);
		options[contentType] = value;
	}

	// ? Set the player when the script is loaded
	useEffect(() => {
		const doesPlayerHaveContent = document.getElementById(PLAYER_ID)?.hasChildNodes();

		if (!loading && !doesPlayerHaveContent) {
			const player = new window.Twitch.Player(PLAYER_ID, options);
			setPlayer(player);
			player.setVolume(1);
		}
	}, [loading, options]);

	// ? Change the content when the stage changes
	useEffect(() => {
		if (options.channel) {
			const currentChannel = player?.getChannel();
			if (currentChannel != options.channel) {
				player?.setChannel(options.channel);
			}
		} else if (options.video) {
			const currentVideo = player?.getVideo();
			if (currentVideo != options.video) {
				player?.setVideo(options.video);
			}
		} else if (options.collection) {
			const currentCollection = player?.getCollection();
			if (currentCollection != options.collection) {
				player?.setCollection(options.collection);
			}
		}

		if (isExpanded) {
			player?.play();
		}
	}, [isExpanded, options.channel, options.collection, options.video, player]);

	// ? Play / Pause the player when the isExpanded state changes
	useEffect(() => {
		if (!player) {
			return;
		}

		if (!isExpanded) {
			setTimeout(() => {
				player.pause();
			}, 500);
		} else {
			player.play();
		}
	}, [isExpanded, player]);

	return (
		<TwitchPlayer id={PLAYER_ID} isLoading={loading}>
			{loading && <Image src="/images/twitch-logo.png" alt="Twitch livestream" width="64" height="64" />}
		</TwitchPlayer>
	);
};

const TwitchHeaderControls = () => {
	const {
		state: { allStages, currentStage, isExpanded, currentStream },
	} = useContext(TwitchLiveStreamStateContext);
	const {
		actions: { setCurrentStage, setIsExpanded },
	} = useContext(TwitchLiveStreamActionsContext);
	const hasMultipleStages = allStages.length > 1;
	const { stage_name, id } = currentStage;

	const onClickPrevious = () => {
		// If not previous, then go to last
		const currentStageIndex = allStages.findIndex((stage) => stage.id === id);
		const previousStage = allStages[currentStageIndex - 1];

		if (previousStage) {
			setCurrentStage(previousStage);
		} else {
			setCurrentStage(allStages[allStages.length - 1]);
		}
	};

	const onClickNext = () => {
		const currentStageIndex = allStages.findIndex((stage) => stage.id === id);
		const nextStage = allStages[currentStageIndex + 1];

		if (nextStage) {
			setCurrentStage(nextStage);
		} else {
			setCurrentStage(allStages[0]);
		}
	};

	return (
		<TwitchHeaderControlsWrapper isExpanded={isExpanded}>
			<TwitchPlayerTitle>
				{isExpanded ?
						(
							<>
								{hasMultipleStages && <SpriteIcon id="arrow-back-bold" width="16" height="16" onClick={onClickPrevious} />}
								<span>{stage_name}</span>
								{hasMultipleStages && <SpriteIcon id="arrow-forward-bold" width="16" height="16" onClick={onClickNext} />}
							</>
						) :
						(
							<span>{currentStream.name}</span>
						)}
			</TwitchPlayerTitle>
			<Button
				type={isExpanded ? "outline-secondary" : "secondary"}
				onClick={() => setIsExpanded(!isExpanded)}
				id="twitch_livestream-expand_button"
			>
				{isExpanded ? "Hide Livestream" : "Watch Livestream"}
			</Button>
		</TwitchHeaderControlsWrapper>
	);
};

const TwitchStreamContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		state: { isExpanded },
	} = useContext(TwitchLiveStreamStateContext);

	return <Container isExpanded={isExpanded}>{children}</Container>;
};

export { COLLAPSABLE_ID, PLAYER_ID, TwitchHeaderControls, TwitchStreamContainer };
export default TwitchLiveStream;
