import { Panel } from "@components/core/Panel";
import { CollectionControls } from "@components/headings/CollectionControls";
import { TracksList } from "@components/lists/TracksList";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import {
	Footer,
	Line,
	PreText,
	TitleContainer,
	Wrapper,
} from "./TopTracks.style";
interface Props {
	title: React.ReactNode[] | React.ReactNode | JSX.Element | string;
	footer?: React.ReactNode[] | React.ReactNode | JSX.Element | string;
	preText?: string;
	isPanelList?: boolean;
	tracks?: Track[];
	dataTestId?: string;
	location?: string;
}

const TopTracks: React.FC<Props> = ({
	title,
	footer,
	preText,
	isPanelList,
	tracks = [],
	dataTestId,
	location,
}) => {
	const isXl = useMediaQuery({ query: device.xl });
	return (
		<Wrapper>
			<Panel
				actionsLeft={(
					<CollectionControls
						tracks={tracks}
						showPlay
						showAddToQueue
						showAddToPlaylist
					/>
				)}
				title={(
					<TitleContainer>
						{preText && <PreText>{preText}</PreText>}
						{title}
					</TitleContainer>
				)}
			>
				<Line />
				<TracksList
					location={location}
					tracks={tracks}
					direction={isPanelList ? "row" : "column"}
					isLoading={false}
					forceHover={isXl}
					isPanelList={isPanelList}
					showNumbers
					dataTestId={dataTestId}
				/>
				<Footer>{footer}</Footer>
			</Panel>
		</Wrapper>
	);
};

export default TopTracks;
