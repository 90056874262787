import styled from "styled-components";

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  height: 34px;
  padding-right: 12px;

  border-radius: 22.5px;
  transition: background 0.2s;

  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.neutrals.primary[600]};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
