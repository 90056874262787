import { useEffect, useRef, useState } from "react";

/**
 * A custom React hook for implementing polling behavior with asynchronous callbacks.
 *
 * @param {Function} asyncCallback - The asynchronous callback function to be executed in each polling interval.
 * @param {Array} [dependencies = []] - An array of dependencies that trigger a re-polling if any of them change.
 * @param {Object} [options = {}] - Polling configuration options.
 * @param {number} [options.interval = 10000] - The interval in milliseconds between each poll. Default is 10 seconds.
 * @param {Function} [options.onCleanUp = (() => {})] - A callback function to be executed when the polling is cleaned up.
 * @returns {Object} An object containing control functions for the polling behavior.
 * @returns {boolean} returns.isPollAlive - A boolean value indicating whether the polling is active or not.
 * @returns {Function} returns.killPoll - A function to stop the polling behavior.
 * @returns {Function} returns.revivePoll - A function to resume the polling behavior if stopped.
 */

type PollingOptions = {
	interval?: number;
	onCleanUp?: () => void;
};

type PollingResult<T> = {
	isPollAlive: boolean;
	killPoll: () => void;
	revivePoll: () => void;
	data: T | null;
};

type AsyncCallback<T> = () => Promise<T>;

const usePolling = <T>(
	{ asyncCallback, dependencies = [], options = {} }: { asyncCallback: AsyncCallback<T>; dependencies?: any[]; options?: PollingOptions },
): PollingResult<T> => {
	const { interval = 10000, onCleanUp = () => {} } = options;

	const [dead, kill] = useState(false);
	const [data, setData] = useState<T | null>(null);
	const timeoutIdRef = useRef<number | null>(null);

	useEffect(() => {
		if (dead) {
			return;
		}

		let _stopped = false;

		(async function pollingCallback() {
			try {
				const result = await asyncCallback();
				setData(result); // Update the data state with the result
			} finally {
				// Set timeout after it finishes, unless stopped
				timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval) as any;
			}
		})();

		// Clean up if dependencies change
		return () => {
			_stopped = true; // Prevent race conditions
			clearTimeout(timeoutIdRef.current!);
			onCleanUp();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies, interval, dead]);

	return {
		isPollAlive: !dead,
		killPoll: () => kill(true),
		revivePoll: () => kill(false),
		data,
	};
};

export default usePolling;
