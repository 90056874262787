import styled from "styled-components";

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TopLink = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  display: flex;
  align-items: row;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const NewOnBeatportWrapper = styled.div`
  flex: 7;
  width: 100%;

  div.hero-release,
  div.hero-chart {
    img {
      width: 100%;
    }
    span > span {
      padding: 70% 0px 0px !important;
    }
  }

  div.hero-image {
    img {
      width: 100%;
    }
  }
`;

export const DJChartsWrapper = styled.div`
  flex: 3;
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;

  @media ${(props) => props.theme.device.xl} {
    flex-direction: row;
  }
`;

export const MainSection = styled.div`
  width: 100%;
`;

export const RightSeciton = styled.div`
  display: none;
  flex-direction: column;
  width: inherit;
  gap: 24px;

  @media ${(props) => props.theme.device.md} {
    display: flex;
    width: 260px;
    min-width: 260px;
  }

  @media ${(props) => props.theme.device.xl} {
    display: flex;
    width: 320px;
    min-width: 320px;
  }

  @media ${(props) => props.theme.device.xxl} {
    display: flex;
    width: 360px;
    min-width: 360px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
